/// @group mixins
$html-font-size: 16px;
@function stripUnit($value) {
    @return $value / ($value * 0 + 1);
}

@function toRem($pxValue) {
    @return #{stripUnit($pxValue) / stripUnit($html-font-size)}rem;
}

