
/* 拖动的时候的样式 */
.SortableHelper {
    box-shadow: rgba(0, 0, 0, 0.075) 0 1px 6px, rgba(0, 0, 0, 0.075) 0 1px 4px;
    background-color:azure;
  }

  .ant-upload-select-picture-card {
      width: 200px!important;
      height: 104px!important;
  }
  .ant-upload-picture-card-wrapper{
    margin-top: 30px;
  }