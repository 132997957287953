@import '../../assets/css/variables';
@import '../../assets/css/mixins';

.task{
  .task_table {
    border: 1px solid $table_border_gray;
    border-bottom: none;
    .status_publish {
      color: $table_action_blue;
      font-weight: bold;
    }
    .status_hidden {
      color: $table_action_orange;
      font-weight: bold;
    }
    .status_Draft {
      color: $table_action_gray;
      font-weight: bold;
    }
    a {
      color: $table_action_gray;
      font-size: 1rem;
    }
    .ant-table-thead {
      .ant-table-cell {
        color: $table_action_gray;
        background-color: $table_thread_bg_gray;
      }
    }
  }
  
  .count_total{
    color: $table_action_gray;
  }
}
