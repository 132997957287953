
/// @group Colors
/// Standard Palette
/// Base
$mainColor: #344854;
$secondaryCorlor: #0A78BE;
$lightColor: #ebebeb;
$buttonHover: #0b8ada;
$gray: #1C2B33;
$grayLight: #F5F6F6;
$grayTip: #9f9f9f;

$table_action_blue: #1877F2;
$table_action_gray: #67788A;
$table_action_orange: #FFAA00;
$table_thread_bg_gray: #F5F6F6;
$table_border_gray: #CBD2D9;

$success_color: #43A047;
$warning_color: #FFAA00;
$error_color: #F44336;
$info_color: #0A78BE;

/* Font size variations
    *********************** */
$font-xs: 0.75rem !default;
$font-s: 0.875rem !default;
$font-m: 1rem !default;
$font-l: 1.125rem !default;
$font-xl: 1.25rem !default;
$font-xxl: 1.375rem !default;
$font-xxxl: 1.5rem !default;
$font-1x: 2.125rem !default;
$font-2x: 2.25rem !default;
$font-3x: 3.75rem !default;
$font-4x: 5.625rem !default;

    /* Line Height palette
    **************** */
$line-height-xs: 1rem !default;
$line-height-s: 1.125rem !default;
$line-height-m: 1.25rem !default;
$line-height-l: 1.375rem !default;
$line-height-xl: 1.5rem !default;
$line-height-xxl: 1.625rem !default;
$line-height-xxxl: 2.375rem !default;
$line-height-1x: 2.75rem !default;
$line-height-2x: 4rem !default;