@import '../../assets/css/variables';
@import '../../assets/css/mixins';

.report-tip {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.25px;
    color: $mainColor;
    margin-bottom: 56px;
}

.reports-content {
    min-height: calc(100vh - 72px) !important;
}