
@import 'variables';


.clearfix:after{visibility:hidden;display: block;font-size:0;content:" ";clear:both;height:0;}
* html .clearfix{ zoom: 1; } /* IE6 */
*:first-child+html .clearfix { zoom: 1; } /* IE7 */

#root {
  height: 100%;
}
.App{
  .ant-layout{
    min-height: 100vh;
  }
  .ant-layout-sider{
    background-color: $mainColor;
  }
  .ant-menu-inline {
    & > .ant-menu-item{
      height: 4rem;
    }
  }
  .font-color-main{
    color:$mainColor;
  }
  .font-color-sec{
    color:$secondaryCorlor;
  }

  .title-bold{
    font-weight: bold;
  }
  .color-gray-driver{
    border-top: 1px solid rgba(70, 90, 105, 1);
    margin: 1rem 0;
  }
  .ant-menu{
    &.ant-menu-dark{
      background-color: $mainColor;
      &.ant-menu-dark:not(.ant-menu-horizontal){
        .ant-menu-item-selected{
          background-color: $secondaryCorlor;
        }
      }
    }
    
  }
  .logo {
    height: 2rem;
    margin: 2rem 1rem;
    background: rgba(255, 255, 255, 0.2);
    background: url("../../assets/images/sideBarLogo.png") no-repeat;
  }
  .content-div{
    // height: 94vh;
      width: 100%;
      overflow-y: auto;
  }
  .site-layout-sub-header-background {
    background: #fff;
  }

  .site-layout-background {
    background: #fff;
  }
  [data-theme="dark"] .site-layout-sub-header-background {
    background: #141414;
  }

  .flexBetweenBar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  }
  .input_bottom_only{
    &.ant-input-affix-wrapper{
      border-top:none;
      border-left:none;
      border-right:none;
    }
    
    &.ant-input{
      border-top:none;
      border-left:none;
      border-right:none;
    }
    &.gray_bg{
      background-color: $grayLight;
    }
  }
  
  .title-bold-lable{
    label{
      font-weight: bold;
    }
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: $secondaryCorlor; 
  }
  .ant-radio-inner::after {
    background-color: $secondaryCorlor; 
  }
  .ant-radio-checked::after {
    border: 1px solid $secondaryCorlor;
  }
  .ant-modal-confirm-content {
    height: 100px;
  }
  .text_bold {
    font-weight: bold;
  }
  .ant-layout-sider-children{
    position: fixed;
    width: 200px;
  }
 
}

//reset modal
// .ant-modal-root{
//   .ant-modal{
//     top: 30%;
//   }
// }
.font-color-success{
  color: $success_color;
}
.font-color-warning{
  color:$warning_color;
}
.font-color-error{
  color:$error_color;
}
.font-color-info{
  color:$info_color;
}
.font-color-tip{
  color:$grayTip;
  height: 1.5rem;
  font-size: toRem(14);
  margin-bottom: .5rem;
}

.ant-modal-confirm-confirm {
  .ant-modal-confirm-body {
    > .anticon {
      &.font-color-error{
        color: $error_color !important;
      }
      &.font-color-warning{
        color: $warning_color !important;
      }
      &.font-color-success{
        color: $success_color !important;
      }
      &.font-color-info{
        color: $info_color !important;
      }
    } 
  }
}