@import '../../assets/css/variables';
@import '../../assets/css/mixins';

.categories_table{
  tr.drop-over-downward td {
    border-bottom: 2px dashed $lightColor;
    background-color:  $lightColor;
  }
  
  tr.drop-over-upward td {
    border-top: 2px dashed $lightColor;
    background-color:  $lightColor;
  }
  table{
    th{
      display: none;
    }
    .ant-table-tbody{
        tr{
          &:first-child{
            td{
              border-top: 1px solid #f0f0f0;
            }
          }
          td{
            &:first-child{
              border-left: 1px solid #f0f0f0;
            }
            &:last-child{
              border-right: 1px solid #f0f0f0;
            }
          }
        }
       
    }
  }
}