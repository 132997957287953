@import 'variables';
@import '_mixins';

.login{
    .ant-btn-primary{
        border-color: $secondaryCorlor;
        background: $secondaryCorlor;
    }
    .ant-btn-primary:hover{
        border-color: $buttonHover;
        background: $buttonHover;
    }
}
.categories{
    .ant-btn-primary{
        border-color: $secondaryCorlor;
        background: $secondaryCorlor;
        &[disabled]{
            color: rgba(0,0,0,.25);
            border-color: #d9d9d9;
            background: #f5f5f5;
            text-shadow: none;
            box-shadow: none;
        }
    }
    .ant-btn-primary:hover{
        border-color: $buttonHover;
        background: $buttonHover;
    }
}
.promotional{
    .ant-btn-primary{
        border-color: $secondaryCorlor;
        background: $secondaryCorlor;
        &[disabled]{
            color: rgba(0,0,0,.25);
            border-color: #d9d9d9;
            background: #f5f5f5;
            text-shadow: none;
            box-shadow: none;
        }
    }
    .ant-btn-primary:hover{
        border-color: $buttonHover;
        background: $buttonHover;
    }
}

.task{
    .ant-btn-primary{
        border-color: $secondaryCorlor;
        background: $secondaryCorlor;
    }
    .ant-btn-primary:hover{
        border-color: $buttonHover;
        background: $buttonHover;
    }
}
.createTask{
    .ant-btn-primary{
        border-color: $secondaryCorlor;
        background: $secondaryCorlor;
        &[disabled]{
            color: rgba(0,0,0,.25);
            border-color: #d9d9d9;
            background: #f5f5f5;
            text-shadow: none;
            box-shadow: none;
        }
    }
    .ant-btn-primary:hover{
        border-color: $buttonHover;
        background: $buttonHover;
        &[disabled]{
            color: rgba(0,0,0,.25);
            border-color: #d9d9d9;
            background: #f5f5f5;
            text-shadow: none;
            box-shadow: none;
        }
    }
}
.ant-modal-confirm-btns{
    .ant-btn-primary{
        border-color: $secondaryCorlor;
        background: $secondaryCorlor;
    }
    
    .ant-btn-primary:hover{
        border-color: $buttonHover;
        background: $buttonHover;
    }
}
.logout_btn {
    .ant-btn-primary{ 
        background-color: $mainColor;
        border-color: #fff;
    }
   
}

.ant-btn-lg{
    border-radius: toRem(4)!important;
}