
@import '../../assets/css/variables.scss';
@import '../../assets/css/mixins';

.login{
  width: 100%;
  height: 100%;
  position: absolute;
  &.login_bg{
    background-color: $mainColor;
  }
  .login_wrapper{
    position: absolute;
    background: #fff;
    width: toRem(600);
    height: toRem(480);
    padding: toRem(60) toRem(100) 0 toRem(100);
    top: 20%;
    left: 50%;
    margin-left: -18.5rem;
  }
  .login_btn_wrapper{
    margin: auto;
    text-align: center;
    padding: 1rem 0 0 0 ;
    .login_btn{
      width: 14rem;
      height: 2.8rem;
      font-size: 1rem;
    }
  }
  
  &.confirm{
    .login_wrapper{
      position: absolute;
      background: #fff;
      width: toRem(600);
      min-height: toRem(540);
      height: auto;
      padding: toRem(60) toRem(100) 0 toRem(100);
    }
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: $secondaryCorlor;
  }
  .ant-form-item-label > label{
      font-weight: bold;
  }
  .logo_title{    
    margin-bottom: 1.5rem;
    .title{      
      .logo{        
        width: toRem(250);
        height: toRem(50);
        margin: auto;
        text-align: center;
        background: url("../../assets/images/loginLogo.png") no-repeat;
      }
    }
    .subtitle{
      width: 100%;
      text-align: center;
      color: $gray;
      padding: .5rem;
  
    }
    .tip{
      font-weight: bold;
      color: $secondaryCorlor;
    }
  }  
  .ant-input-affix-wrapper{
    border-top:none;
    border-left:none;
    border-right:none;
  }
  
  .ant-input{
    border-top:none;
    border-left:none;
    border-right:none;
  } 

}
