@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';


.createTask {
  .hidden {
    display: none;
  }

  .localTask {
    background-color: $grayLight;
    width: 100%;
    height: auto;
    padding: 0.8rem 0 0.8rem 0.8rem;
    //margin: 1rem -1rem 0 -1rem;
    .section_items {
      width: 600px;
      position: relative;
      // .section_left_border{
      //     position: absolute;
      //     width: 1rem;
      //     height: 100%;
      //     color:#ffff;
      // }
    }

    .removeSection {
      width: toRem(150px);
      float: right;
      text-align: right;
    }
  }

  .color-gray-driver {
    border-top: 1px dashed $secondaryCorlor;
  }

  .draft_btn {
    color: $secondaryCorlor;
    border-color: $secondaryCorlor;

    &[disabled] {
      color: rgba(0, 0, 0, .25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
    }
  }

  .avatar-uploader {
    min-height: 8rem;
    max-height: 12rem;
    margin-bottom: 1rem
  }

  .ant-upload {
    line-height: 2.375;

    &.ant-upload-select {
      margin-right: toRem(8px);
      margin-bottom: toRem(8px);
      text-align: center;
      vertical-align: top;
      background-color: #fafafa;
      border: toRem(1px) dashed #d9d9d9;
      border-radius: toRem(2px);
      cursor: pointer;
      transition: border-color 0.3s;
      padding: .8rem 1rem;
      min-width: toRem(200px);
      min-height: toRem(120px);
    }
  }

  .promotional {
    .ant-btn-primary[disabled] {
      color: rgba(0, 0, 0, .25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
    }
  }

  .note_tip {
    color: $secondaryCorlor;
    font-size: toRem(14);
    font-weight: bold;
    padding: toRem(5) 1rem;
    border: 1px solid #f5f5f5;
    width: fit-content;
    width: -webkit-fit-content;
    border-radius: toRem(20);
    margin-bottom: 1rem;
    box-shadow: 1px 2px 7px 2px #f5f5f5
  }

  .upload-txt-color {
    color: $grayTip;
  }

  .ql-editor {
    min-height: 300px;
  }
}

.ant-btn-dangerous {
  color: #fff !important;
  background-color: $error_color !important;

  &:hover {
    color: $error_color !important;
    background-color: #fff !important;
  }
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: '10px';
  font-size: 10px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: '12px';
  font-size: 12px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: '14px';
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: '16px';
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: '18px';
  font-size: 18px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: '20px';
  font-size: 20px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: '24px';
  font-size: 24px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
  content: '26px';
  font-size: 26px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  content: '32px';
  font-size: 32px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48px"]::before {
  content: '48px';
  font-size: 48px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value) !important;
}
