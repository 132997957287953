
@import '../../assets/css/variables';
@import '../../assets/css/mixins';

.taskurl_list{
  display: flex;
  flex-flow: row nowrap;
  .taskurl_lable{
    width: auto;
    padding:toRem(10) toRem(10) 0 0;
    font-weight: bold;
  }
  .taskurl_input{
    width: 600px;
  }
}
.img_list{
  position: relative;
  .img_front_modal{
    position: absolute;
    width: 100%;
    background: $mainColor;
    height: 100%;
    z-index: 999;
    opacity: .2;
  }  
}

.img_list_disabled{
  display: none;
}
